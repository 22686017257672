<template>
  <div class="email-info">
    <span class="title">From:</span><span>{{ email.from }}</span><br>
    <span class="title">To:</span><span>{{ email.to }}</span><br>
    <span class="title">Subject:</span><span>{{ email.subject }}</span><br>
    <span class="title">Date:</span><span>{{ email.dateFormatted }}</span>
    <hr class="my-8">
    <p v-html="email.content"></p>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      email: {},
      emailLogId: ""
    };
  },

  methods: {
    ...mapActions("paymentRequest", ["getPaymentRequestEmail"]),

    async getEmailDetail() {
      await this.getPaymentRequestEmail(this.emailLogId).then((result) => {
        this.email = result.data.data;
      }).catch((ex) => {
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-times",
        });
      });
    }
  },

  mounted() {
    this.emailLogId = this.$route.params.logid;
    this.getEmailDetail();
  }
};
</script>
<style scoped>
  .email-info {
    padding: 10px 20px;
  }

  .email-info span.title {
    display: inline-block;
    width: 70px;
  }
</style>

